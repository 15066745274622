import React, { useRef, useState } from "react";

import Input from "../../../components/UI/Input/Input";
import Button from "../../../components/UI/Button/Button";

const Newsletter = ({ colStyles }) => {
  const [message, setMessage] = useState("");
  const nameInputRef = useRef();
  const emailInputRef = useRef();

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const name = nameInputRef.current.value;
    const email = emailInputRef.current.value;

    if (!name || !email || name.trim() === "" || email.trim() === "" || !email.includes("@")) {
      setMessage(<p className="text-danger">{"Please enter a valid name and email..."}</p>);
      return;
    }

    const response = await fetch("https://shredda.co.uk/api/frontend/site/newsletter/", {
      method: "POST",
      body: JSON.stringify({ name, email }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const responseData = await response.json();

    if (responseData.message === "Subscribed To Newsletter...") {
      setMessage(<p className="text-success">{responseData.message}</p>);
    } else {
      setMessage(<p className="text-danger">{responseData.message}</p>);
    }

    setTimeout(() => setMessage(""), 3000);

    nameInputRef.current.value = "";
    emailInputRef.current.value = "";
  };

  return (
    <div className={colStyles}>
      <form onSubmit={onSubmitHandler}>
        <h6 className="text-uppercase">Newsletter</h6>

        <hr />

        <div className="">
          {message && message}

          <Input required ty="text" id="name" ph="Name" rf={nameInputRef} />

          <Input required ty="email" id="email" ph="Email Address" rf={emailInputRef} />

          <Button colour="btn-success" label="Subscribe" />
        </div>
      </form>
    </div>
  );
};

export default Newsletter;
