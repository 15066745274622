import React, { Suspense } from "react";
// import React, { Suspense, useContext } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// import AuthContext from "./store/authContext";

import Layout from "./layout/Layout";

import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";

import Spinner from "./components/UI/Spinner/Spinner";

const About = React.lazy(() => import("./pages/About/About"));
const Privacy = React.lazy(() => import("./pages/Privacy/Privacy"));
const Terms = React.lazy(() => import("./pages/Terms/Terms"));
const Vote = React.lazy(() => import("./pages/Vote/Vote"));
const Suggest = React.lazy(() => import("./pages/Suggest/Suggest"));
const Contact = React.lazy(() => import("./pages/Contact/Contact"));
const UserAuth = React.lazy(() => import("./pages/UserAuth/UserAuth"));

const App = () => {
  // const authCtx = useContext(AuthContext);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <NotFound />,
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<Spinner />}>
              <Home />
            </Suspense>
          ),
        },
        {
          path: "about",
          element: (
            <Suspense fallback={<Spinner />}>
              <About />
            </Suspense>
          ),
        },
        {
          path: "privacy",
          element: (
            <Suspense fallback={<Spinner />}>
              <Privacy />
            </Suspense>
          ),
        },
        {
          path: "terms",
          element: (
            <Suspense fallback={<Spinner />}>
              <Terms />
            </Suspense>
          ),
        },
        {
          path: "vote",
          element: (
            <Suspense fallback={<Spinner />}>
              <Vote />
            </Suspense>
          ),
        },
        {
          path: "suggest",
          element: (
            <Suspense fallback={<Spinner />}>
              <Suggest />
            </Suspense>
          ),
        },
        {
          path: "contact",
          element: (
            <Suspense fallback={<Spinner />}>
              <Contact />
            </Suspense>
          ),
        },
        {
          path: "auth",
          element: (
            <Suspense fallback={<Spinner />}>
              <UserAuth />
            </Suspense>
          ),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
