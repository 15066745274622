import React, { useContext } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";

import ThemeContext from "../../store/themeContext";
import AuthContext from "../../store/authContext";
import Logo from "../../components/Logo/Logo";

import "./Header.css";

const navActive = (navStatus) => (navStatus.isActive ? "nav-link active aria-current" : "nav-link");

const Header = () => {
  const themeCtx = useContext(ThemeContext);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const logoutHandler = () => {
    handleCollapse();
    authCtx.onLogout();
    navigate("/");
  };

  const handleCollapse = () => {
    const nav = document.getElementById("navbarNav");
    const btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  };

  const changeTheme = (e) => {
    handleCollapse();
    themeCtx.onThemeChange(e.target.value);
  };

  return (
    <header className={`px-4 container-fluid border-bottom border-${themeCtx.theme === "dark" ? "light" : "dark"}`}>
      <nav
        className={`navbar navbar-expand-lg navbar-${themeCtx.theme === "dark" ? "dark" : "light"} position-relative`}>
        <button
          id="navbarBtn"
          className="navbar-toggler m-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle Navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <Link className="navbar-brand fs-3" to="/" onClick={handleCollapse}>
          <Logo />
        </Link>

        <div className="collapse navbar-collapse text-uppercase" id="navbarNav">
          <div className="navbar-nav ms-auto">
            <NavLink className={(nD) => navActive(nD)} to="/" onClick={handleCollapse}>
              <i className="fa-solid fa-house" title="Home"></i> <span className="ms-2">Home</span>
            </NavLink>

            {!authCtx.isLoggedIn && (
              <NavLink className={(nD) => navActive(nD)} to="/auth" onClick={handleCollapse}>
                <i className="fa-solid fa-user" title="My Account"></i> <span className="ms-2">My Account</span>
              </NavLink>
            )}

            {authCtx.isLoggedIn && (
              <>
                <div className="nav-item dropdown">
                  <div
                    className="nav-link dropdown-toggle"
                    id="myAccountDD"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <i className="fa-solid fa-user" title="My Account"></i> <span className="ms-2">My Account</span>
                  </div>

                  <div
                    className={`dropdown-menu dropdown-menu-end dropdown-menu-${
                      themeCtx.theme === "dark" ? "dark" : "light"
                    }`}
                    aria-labelledby="myAccountDD">
                    <NavLink className="dropdown-item" to={`/dashboard`} onClick={handleCollapse}>
                      My Dashboard
                    </NavLink>

                    <span className="dropdown-item text-danger" role="button" onClick={logoutHandler}>
                      LOGOUT
                    </span>
                  </div>
                </div>
              </>
            )}

            <div className="nav-item dropdown">
              <div
                className="nav-link dropdown-toggle"
                id="settings"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <i className="fa-solid fa-gear" title="Settings"></i> <span className="ms-2">Settings</span>
              </div>

              <div
                className={`px-3 dropdown-menu dropdown-menu-end dropdown-menu-${
                  themeCtx.theme === "dark" ? "dark" : "light"
                }`}
                aria-labelledby="settings">
                THEME
                <div className="mt-1 form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="theme"
                    id="darktheme"
                    value="dark"
                    checked={themeCtx.theme === "dark"}
                    onChange={changeTheme}
                  />
                  <label className="form-check-label" htmlFor="darktheme">
                    Dark
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="theme"
                    id="lighttheme"
                    value="light"
                    checked={themeCtx.theme === "light"}
                    onChange={changeTheme}
                  />
                  <label className="form-check-label" htmlFor="lighttheme">
                    Light
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
