import React from "react";

import Header from "../../layout/Header/Header";
import Footer from "../../layout/Footer/Footer";

const NotFound = () => {
  return (
    <>
      <Header />

      <main className="p-3 text-center">
        <div>404 Page Not Found</div>
      </main>

      <Footer />
    </>
  );
};

export default NotFound;
