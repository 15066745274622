import React from "react";

import classes from "./Logo.module.css";

const Logo = () => {
  return (
    <div className={classes.logo}>
      Sh<span className={classes.red}>red</span>dA
    </div>
  );
};

export default Logo;
