import React from "react";

import classes from "./MadeBy.module.css";

const MadeBy = () => {
  return (
    <div className={classes.madeby}>
      <a href="https://andyjacko.com" rel="noreferrer" target="_blank">
        - Andy Jacko -
      </a>
    </div>
  );
};

export default MadeBy;
