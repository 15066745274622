import React from "react";

const Spinner = () => {
  return (
    <div className="p-3 d-flex align-items-center">
      <div className="spinner-border text-danger" role="status"></div>
      <span className="ms-2">Loading...</span>
    </div>
  );
};

export default Spinner;
