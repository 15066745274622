import React, { useCallback, useState } from "react";

const ThemeContext = React.createContext({
  theme: "dark",
  onThemeChange: () => {},
});

const retriveStoredTheme = () => {
  const storedTheme = localStorage.getItem("theme");

  if (!storedTheme) {
    return {
      theme: "dark",
    };
  }

  document.getElementById("theme").setAttribute("href", `theme/${storedTheme}.css`);

  return {
    theme: storedTheme,
  };
};

export const ThemeContextProvider = ({ children }) => {
  const themeData = retriveStoredTheme();

  let initialTheme;

  if (themeData) {
    initialTheme = themeData.theme;
  }

  const [theme, setTheme] = useState(initialTheme);

  const onThemeChange = useCallback((theme) => {
    setTheme(theme);
    localStorage.setItem("theme", theme);

    document.getElementById("theme").setAttribute("href", `theme/${theme}.css`);
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        theme: theme,
        onThemeChange: onThemeChange,
      }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
