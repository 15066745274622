import React from "react";

const linkStyles = `text-decoration-none `;

const SocialLinks = ({ colStyles }) => {
  return (
    <div className={colStyles}>
      <h6 className="text-uppercase">Social</h6>
      <hr />
      <nav className="d-flex justify-content-evenly fs-2">
        <a className={linkStyles} href="https://www.tiktok.com/" title="Tiktok" target="_blank" rel="noreferrer">
          <i className="fa-brands fa-tiktok"></i>
        </a>

        <a className={linkStyles} href="https://www.instagram.com/" title="Instagram" target="_blank" rel="noreferrer">
          <i className="fab fa-instagram"></i>
        </a>

        <a className={linkStyles} href="https://www.facebook.com/" title="Facebook" target="_blank" rel="noreferrer">
          <i className="fa-brands fa-facebook-f"></i>
        </a>
      </nav>
    </div>
  );
};

export default SocialLinks;
