import React from "react";

const About = ({ colStyles }) => {
  return (
    <div className={colStyles}>
      <h6 className="text-uppercase">Shredda</h6>

      <hr />

      <p className="small">HIIT/Strength Workout Generator</p>

      <p className="small">Generate workouts with customisable options.</p>

      <p className="small">
        Create an account to save your workouts, vote for/suggest new features, earn badges and much more...
      </p>
    </div>
  );
};

export default About;
