import React from "react";

import Logo from "../../components/Logo/Logo";

import classes from "./Home.module.css";

const Home = () => {
  return (
    <>
      <div className={`py-5 text-center ${classes.logobg}`}>
        <div className="display-1">
          <Logo />
        </div>

        <div className={classes.rwg}>Workout Generator</div>
      </div>
    </>
  );
};

export default Home;
