import React, { useContext } from "react";

import ThemeContext from "../../store/themeContext";
import About from "./About/About";
import QuickLinks from "./QuickLinks/QuickLinks";
import SocialLinks from "./SocialLinks/SocialLinks";
import Newsletter from "./Newsletter/Newsletter";
import MadeBy from "./MadeBy/MadeBy";

const copyYear = new Date().toLocaleDateString("en-GB", { year: "numeric" });
const colStyles = "col-md-6 col-lg-3 mb-4";

const Footer = () => {
  const themeCtx = useContext(ThemeContext);

  return (
    <footer className={`container-fluid py-3 border-top border-${themeCtx.theme === "dark" ? "light" : "dark"}`}>
      <div className="row p-3 mt-3">
        <About colStyles={colStyles} />
        <QuickLinks colStyles={colStyles} />
        <SocialLinks colStyles={colStyles} />
        <Newsletter colStyles={colStyles} />
      </div>

      <hr className={`text-${themeCtx.theme === "dark" ? "light" : "dark"}`} />

      <div className="text-center">
        <small>Copyright &copy; {copyYear} Shredda</small>

        <MadeBy />
      </div>
    </footer>
  );
};

export default Footer;
