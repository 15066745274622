import React, { useContext } from "react";

import { Link } from "react-router-dom";

import AuthContext from "../../../store/authContext";

const linkStyles = "text-decoration-none text-capitalize";

const QuickLinks = ({ colStyles }) => {
  const authCtx = useContext(AuthContext);

  return (
    <div className={colStyles}>
      <h6 className="text-uppercase">Links</h6>

      <hr />

      <div className="container-fluid">
        <div className="row">
          <div className="col-6 small">
            <nav>
              <ul className="list-unstyled">
                <li>
                  <Link className={linkStyles} to="/about">
                    About
                  </Link>
                </li>

                <li>
                  <Link className={linkStyles} to="/privacy">
                    Privacy
                  </Link>
                </li>

                <li>
                  <Link className={linkStyles} to="/terms">
                    Terms &amp; Conditions
                  </Link>
                </li>

                <li>
                  <Link className={linkStyles} to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          </div>

          <div className="col-6 small">
            <nav>
              <ul className="list-unstyled">
                <li>
                  <Link className={linkStyles} to="/vote">
                    Vote For Feature
                  </Link>
                </li>

                <li>
                  <Link className={linkStyles} to="/suggest">
                    Suggest A Feature
                  </Link>
                </li>

                {authCtx.isLoggedIn && (
                  <>
                    <li>
                      <Link className={linkStyles} to={`/dashboard`}>
                        My Dashboard
                      </Link>
                    </li>

                    <li>
                      <Link className={linkStyles} to={`/options`}>
                        My Options
                      </Link>
                    </li>
                  </>
                )}

                {!authCtx.isLoggedIn && (
                  <li>
                    <Link className={linkStyles} to="/auth">
                      My Account
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickLinks;
